.cls-2 {
  fill: #65c9ff;
}

.cls-3 {
  fill: #d0c6ac;
}

.cls-4,
.cls-5 {
  fill: #e6e6e6;
}

.cls-4,
.cls-6 {
  clip-rule: evenodd;
}

.cls-12,
.cls-16,
.cls-20,
.cls-22,
.cls-25,
.cls-5 {
  fill-rule: evenodd;
}

.cls-16,
.cls-6 {
  fill: rgba(0, 0, 0, 0.7);
}

.cls-18,
.cls-7 {
  fill: #fff;
}

.cls-7 {
  opacity: 0;
}

.cls-8 {
  clip-path: url(#clip-path);
}

.cls-9 {
  clip-path: url(#clip-path-2);
}

.cls-10 {
  clip-path: url(#clip-path-3);
}

.cls-11 {
  fill: #edb98a;
}

.cls-12 {
  fill: rgba(0, 0, 0, 0.1);
}

.cls-13 {
  clip-path: url(#clip-path-5);
}

.cls-15,
.cls-20 {
  fill: rgba(0, 0, 0, 0.16);
}

.cls-17 {
  clip-path: url(#clip-path-7);
}

.cls-19 {
  fill: #ff4f6d;
}

.cls-21,
.cls-22 {
  fill: rgba(0, 0, 0, 0.6);
}

.cls-23 {
  fill: #724133;
}

.cls-24 {
  opacity: 0.27;
}

.cls-25 {
  fill: #252c2f;
}

.cls-26 {
  fill: #ffcd00;
}

.cls-27 {
  fill: #5b2a04;
}
